<script>
  let synthwaveColors = [
    '#ffd319',
    '#ff901f',
    '#ff2975',
    '#f222ff',
    '#8c1eff'
  ]

  let colorIndex = 0;

  let currentColor = synthwaveColors[0];

  setInterval(() => {
    if (colorIndex == synthwaveColors.length - 1) {
      colorIndex = 0;
    } else {
      colorIndex++;
    }
    currentColor = synthwaveColors[colorIndex];
    console.log('colorIndex', colorIndex)
  }, 3000)
</script>

<div class="colors" style="background-color: {currentColor}"></div>

<style>
  .colors {
    height: 100vh;
    transition: all 3.5s;
  }
</style>